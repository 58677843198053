.fullPageBg {
    background: url('./../../Assets/images/fullPageBg.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.fullPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}

.authHeader {
    text-align: center;
    margin: 2rem 0;
}

.authTitle {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
}

.authPara {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: center;
}

.authParaLink {
    font-weight: 500;
    color: #fff;
    transition: 0.3s linear;
}

.authParaLink:hover {
    color: var(--lightColor);
}

.authFormLinks {
    font-size: 14px;
    text-decoration: none;
    color: var(--lightColor);
    transition: 0.3s linear;
}

.authFooter {
    margin: 1rem 0;
    text-align: center;
}

.authFooterLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    font-size: 12px;
    color: var(--lightColor);
    margin: 0.5rem 0;
}

.authFooterLinks a {
    font-size: 14px;
    text-decoration: none;
    color: var(--lightColor);
    transition: 0.3s linear;
}

.authFooterLinks a:hover {
    color: #fff;
}

.appLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.appLink img {
    width: 120px;
}

@media screen and (max-width: 768px) {

    .authTitle {
        font-size: 26px;
    }

    .appLink img {
        width: 100px;
    }
}

@media screen and (max-width: 575px) {
    .authFormWrapper {
        padding: 1rem 1rem;
    }

    .appLink img {
        width: 80px;
    }
}