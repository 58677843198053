:root {
    --background-color: #042047;
    --text-color: #E2E4E9;
    --border-color: white;
    --accent-color: gray;
    --primaryColor: #013D5F;
    --secondaryColor: #21173A;
    --lightColor: #89a8b9;
    --grayColor: #666666;
    --redColor: #ff0000;
    --greenColor: #42C900;
    --yellowColor: #E5BB45;
}



.swap-modal-for-permission .custom-modal {
    top: 0;
    transform: translate(0, 0);
  }

.swap-modal-for-permission .modal-content {
    background: var(--background-color);
}

.customAccordion {
    border: none;
}

.customAccordion .accordion-header {
    margin-bottom: 0;
    /* border-bottom: 2px solid var(--text-color); */
    background: var(--background-color);
}

.customAccordion .accordion-body {
    background: var(--background-color);
    border-bottom: 1px solid var(--text-color);
}

.customAccordion .accordion-button {
    background: var(--background-color);
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
    text-transform: capitalize;
    border-radius: 0 !important;
    box-shadow: none;
    margin: 0;
    text-shadow: 0 0 10px var(--accent-color);
}

.customAccordion .accordion-button::after {
    filter: brightness(0) invert(1);
}

.btn-close {
    filter: brightness(0) invert(1);
}

.customList .list-group-item {
    position: relative;
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--text-color);
    text-decoration: none;
    background-color: var(--background-color);
    border: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}
.customiseModal input {
    width: 120px;
    padding-left: 0;
    display: block;
    margin: auto;
    max-width: 100%;
    text-align: center;
    padding-right: 0;
}
.eyeButton svg {
    width: 20px;
    height: 20px;
}

.sub-permission {
    color: var(--text-color);
}
.eyeButton svg path {
    fill: var(--lightColor);
}
.eyeButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.3rem;
    font-size: 1rem;
    background: none;
    border: none;
    line-height: 1;
    color: var(--primaryColor);
    outline: #fff0;
}
.passwordWrapper {
    position: relative;
}
.alignInput{
    position: relative;
}
.customCheckBox {
    position: absolute;
    z-index: 4;
    padding: 10px;
    margin: 10px;
}