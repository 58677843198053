.country-dropdown {
    position: absolute;
    width: inherit;
    max-width: 100%;
}

.country-dropdown .country-dropdown-list {
    position: relative;
    z-index: 1;
    background-color: #061f46;
    box-shadow: 0px 1px 10px #0000007a;
    max-height: 250px;
    min-width: 250px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    font-size: 14px;

}

.mainInput-2 {
    background: #013D5F;
    border: none;
    border-bottom: 1px solid #fff;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    width: 100%;
    resize: none;
    border-radius: none;
    outline: none;
}

.select__control {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    background-color: #fff0 !important;
    box-shadow: none !important;

}

.select__control .select__input {
    color: #fff !important;
}

.select__indicators {
    display: none !important;
}

.select__control .select__value-container {
    background-color: #fff0 !important;
}

.select__control .select__multi-value {
    background-color: #013D5F !important;
    color: #fff;
}

.select__control .select__multi-value__label {
    color: #fff;
}

.select__menu {
    background: #013D5F !important;
    max-width: 250px;
}

.select__menu {
    background: #013D5F !important;
}

.select__menu .select__option {
    background: #fff0 !important;
}

.select__menu .select__option:hover {
    background: #fff0 !important;
}