.descriptionLimit {
    text-wrap: balance;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-bold {
    font-weight: 600;
}

.userImageProfile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.user_delete_modal h2{
    font-size: 20px;
}

.user_delete_modal .swal2-html-container{
    font-size: 16px;
}

.user-management-table .customTable {
    /* max-height: 500px; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: unset;
    /* height: 100%; */
}