.copy-text {
    display: flex;
    position: relative;
}

.copy-text input.text {
    padding: 10px;
    font-size: 18px;
    color: #555;
    border: none;
    outline: none;
}

.copy-text button {
    padding: 8px;
    background: #5784f5;
    color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}

.copy-text button:active {
    background: #809ce2;
}

.copy-text button:before {
    content: "Copied";
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}

.copy-text button:after {
    content: "";
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}

.copy-text.active button:before,
.copy-text.active button:after {
    display: block;
}