.dashboardBody {
    background: linear-gradient(120.55deg, #013D5F -1.27%, #22163A 79.83%);
    min-height: 100vh;
    margin-left: 260px;
    padding: 6rem 1rem 1rem;
    transition: ease-in-out .4s;
}

.dashboardBody.expanded {
    margin-left: 60px;
}

@media screen and (max-width: 767px) {

    .dashboardBody.expanded, .dashboardBody  {
        margin-left: 0 !important;
    }

    

}