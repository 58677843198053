.descriptionLimit {
    text-wrap: balance;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-bold {
    font-weight: 600;
}

.special-table .customTable .thumbnail {
    width: 120px;
    border-radius: 7px;
    height: 70px;
}