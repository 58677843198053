.customTable {
    overflow-x: auto;
    min-height: 330px;
}

.customTable table {
    font-size: 14px;
    padding: 0px 0.5rem;
    width: 100%;
}

.customTable table thead tr {
    border-bottom: 1px solid var(--lightColor);
}

.customTable table th, .customTable table td {
    padding: 0.8rem 1rem;
    vertical-align: middle;
    white-space: nowrap;
}

.customTable table td {
    font-weight: 400;
    color: var(--lightColor);
    text-transform: capitalize;
}

.customTable table th {
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.customTable .thumbnail {
    width: 40px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border-radius: 99px;
    margin-right: 0.5rem;
}

.tableDropdownMenu {
    padding: 0;
    border-radius: 0.3rem;
    min-width: auto;
    text-align: left;
    overflow: hidden;
    border: 1px solid #3b2d5e;
}

.tableAction {
    font-size: 15px;
    font-weight: 600;
    display: block;
    text-align: left;
    text-decoration: none;
    padding: 0.5rem 1rem;
    background: #100b16;
    color: #fff;
    border: none;
    outline: none;
    width: 100%;
    transition: 0.3s linear;
}

.tableAction:not(:last-child) {
    border-bottom: 1px solid #3b2d5e;
}

.tableAction:hover {
    color: #fff;
    background: #21173a;
}

.tableActionIcon {
    font-size: 15px;
    margin-right: 0.5rem;
    width: 1rem;
}

.classicToggle {
    color: var(--lightColor);
}