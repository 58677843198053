.sidebar {
    width: 260px;
    height: calc(100vh - 4rem);
    position: fixed;
    transition: all 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
    background: #100b16;
    z-index: 2;
    top: 4rem;
    padding-bottom: 3rem;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
}
.sidebar::-webkit-scrollbar {
    display: none;
}

.sideLink {
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    display: block;
    padding: 0.8rem 1rem;
    background: #fff0;
    border: 1px solid #fff0;
    position: relative;
    transition: 0.2s linear;
}

.sideLink:hover, .sideLink.active {
    color: #fff;
    background: var(--secondaryColor);
    border: 1px solid #3b2d5e;
}

.sideIcon {
    font-size: 16px;
    margin-right: 0.5rem;
}


/*----- Sidebar Collapsed Start -----*/

.sidebar.collapsed {
    width: 60px;
    text-align: center;
}
.toggle-width {
    width: 100% !important;
    text-align: start;
}
.sidebar.collapsed .toggle-width{
    width: 60px;
    text-align: center;
}

.sidebar.collapsed .sideLinkText {
    display: none;
}

.sidebar.collapsed .sideLink:hover .sideLinkText {
    display: block;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primaryColor);
    color: #fff;
    z-index: 999;
    position: absolute;
    padding: 0.8rem 1rem;
    white-space: nowrap;
}

.sidebar.collapsed .sideIcon {
    margin: 0;
}

/*----- Sidebar Collapsed End -----*/


@media screen and (max-width: 767px) {

    .sidebar.collapsed {
        width: 0;
    }

    .sidebar.collapsed .sideLink {
        display: none;
    }
}


.bide-bar-menu {
    background-color: #100b16;
}