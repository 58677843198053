.customTabs {
    justify-content: center;
    border: none;
    gap: 8px;
}

.customTabs .nav-link {
    border: 2px solid #fff;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    min-width: 120px;
    padding: 0.6rem 1.8rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    transition: .3s linear;
    background: #fff0;
    color: #fff;
}

.customTabs .nav-link.active {
    background: #fff;
    color: #000;
}

.categoryItem {
    background: #fff0;
    color: #fff;
    padding: 0.4rem 1rem;
    font-size: 15px;
    border: 2px solid #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    word-break: break-all;
}

.upload-modal .modal-content {
    background: linear-gradient(120.55deg, #013D5F -1.27%, #22163A 79.83%);
}

.image-wrapper .imageInput img {
    width: 100%;
    height: 65px;
    object-fit: contain;
}

.icon-wrapper {
    position:relative;

}

.icon-on-top-right {
    position: absolute;
    top: 20px;
    right: 0px;
    width: 50px;
    height: 50px;
    padding: 0px 10px;
    cursor: pointer;

}

.cross-icon-style {
    background: black;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
  }