.backButton {
    font-size: 16px;
    color: var(--primaryColor);
    background: #fff;
    border: none;
    border-radius: 99px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.4rem;
    transition: 0.3s linear;
}