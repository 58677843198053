.descriptionLimit {
    text-wrap: balance;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-bold {
    font-weight: 600;
}

.coupon-expires input[type="date"]::-webkit-inner-spin-button,
.coupon-expires input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.coupon-expires .checkbox {
    position: absolute;
    bottom: 13px;
    right: 15px;
}

.wrapper-class {
    box-shadow: 0px 1px 10px #0000007a !important;
    background: #061f46 !important;
}


.editor-class {
    background-color: lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
}

.toolbar-class {
    border: 1px solid #ccc;
}

.rdw-editor-toolbar * {
    color: #000 !important;
    box-sizing: content-box;
}

.rdw-image-alignment-options-popup div {
    color: #000 !important;
}

.rdw-editor-toolbar {
    background: #061f46 !important;
    border-color: #061f46 !important;
    margin: 0 !important;
    padding: 1rem !important;
}

.editor-class {
    background-color: #061f46 !important;
    padding: .5rem 1rem;
    border: 1px solid #061f46 !important;
    border-top: 1px solid #fff !important;
    min-height: 200px !important;
    max-height: 500px;
}

.couponImageInput img {
    height: 60px !important;
    max-width: 200px !important;
    object-fit: contain !important;
}

.couponImageInput img.img {
    border: none !important;
}

.coupon-display-image {
    height: 60px !important;
    width: 60px !important;
    object-fit: cover;
    border-radius: 100%;
    border: none !important;
}

.no-accordian-dropdown {
    pointer-events: none;
}

.no-accordian-dropdown button{
/*     display: none; */
    opacity: 0
}