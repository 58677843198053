.customHeader {
    background-color: #100b16;
    position: fixed !important;
    width: 100%;
    z-index: 5;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0px 10px !important;
    box-shadow: 0 3px 6px rgb(0 0 0 / 6%);
    height: 4rem;
}

.customCollapse .navbar-nav {
    align-items: center;
}

.toggleButton {
    display: flex !important;
    align-items: center;
}

/*----- Logo Start -----*/

.siteLogo {
    width: 230px;
    text-align: center;
    outline: none;
}

.siteLogo img {
    width: 90px;
    max-width: 100%;
}

/*----- Logo End-----*/


/*----- Notifcation Start-----*/
.bellIcon {
    font-size: 20px;
}

.notiMenu {
    width: 320px;
    background: #f5f5f5;
    padding: 0 !important;
    border: none !important;
    border-radius: 0.5rem !important;
    box-shadow: 0 3px 6px rgb(0 0 0 / 28%) !important;
}

.notificationsBody {
    height: 160px;
    overflow-y: auto;
}

.notificationsBody::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.notificationsBody::-webkit-scrollbar-track-piece {
    background-color: #ccc;
}

.notificationsBody::-webkit-scrollbar-thumb {
    background-color: var(--primaryColor);
}

.singleNoti {
    display: flex;
    align-items: baseline;
    gap: 1rem;
    text-decoration: none;
    background: #f5f5f5;
    padding: 0.5rem 0.5rem;
    transition: 0.3s linear;
}

.singleNoti:hover {
    background: #e8e8e8;
}

.singleNotiIcon {
    flex-shrink: 0;
}

.notiIcon {
    font-size: 18px;
    color: var(--primaryColor);
    transform: rotate(25deg);
}

.singleNotiContent {
    flex-grow: 0;
}

.notiText {
    font-size: 16px;
    color: #333;
    margin-bottom: 0rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.notiDateTime {
    font-size: 13px;
    margin: 0;
}

.notiFooter {
    font-size: 15px;
    font-weight: 600;
    background: #f5f5f5;
    border-top: 1px solid #ccc;
    text-align: end;
    padding: 0.5rem 0.5rem;
}


/*----- Notifcation End-----*/


.userImage img {
    width: 35px;
    max-width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border-radius: 99px;
}

.userMenu {
    background: #f5f5f5 !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 0.5rem !important;
    box-shadow: 0 3px 6px rgb(0 0 0 / 28%) !important;
    overflow: hidden;
}

.userMenuItem {
    font-size: 15px;
    text-decoration: none;
    color: #333;
    display: block;
    background: #f5f5f5;
    border: none;
    width: 100%;
    text-align: left;
    padding: 0.5rem 0.5rem;
    transition: 0.3s linear;
    border-bottom: 1px solid #ccc;
}

.userMenuItem:hover {
    color: var(--primaryColor);
}




@media screen and (max-width: 767px) {

    .customCollapse {
        background: #fff;
        position: absolute;
        top: 6rem;
        left: 0;
        width: 100%;
        box-shadow: 0px 2px 3px rgb(0 0 0 / 10%);
    }

    .customCollapse .navbar-nav {
        flex-direction: row;
        justify-content: end;
    }

    .customCollapse .navbar-nav .dropdown-menu {
        position: absolute;
    }


}