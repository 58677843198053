/* .stats {
    margin-bottom: 1rem;
    border-right: 2px solid #fff0;
}

.stats:not(:last-child) {
    border-color: #000;
}


@media screen and (max-width: 1200px) and (min-width: 768px) {
    .stats, .stats:not(:last-child) {
        border-color: #fff0;
    }

    .stats:nth-child(odd) {
        border-color: #000;
    }
}

@media screen and (max-width: 767px) {
    .stats, .stats:not(:last-child), .stats:nth-child(odd) {
        border-color: #fff0;
    }
}



 */



.swap-modal {
    backdrop-filter: blur(12px);
}

.swap-modal .modal-content {
    background: #042047;
    max-width: 500px;
    margin: 0 auto;
}

.swap-modal .modal-content .modal-body {
    /* padding: 0 !important; */
    opacity: 1;
    border-radius: 10px;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.swap-modal input[type="number"] {
    height: 30px;
    width: 80px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    outline: none;
    text-align: center;
}

.swap-modal h2 {
    font-size: 20px;
    line-height: 1;
}

.swap-modal h2 b {
    font-size: 24px;
}

/* Chrome, Safari, Edge, Opera */
.swap-modal input::-webkit-outer-spin-button,
.swap-modal input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.swap-modal input[type=number] {
    -moz-appearance: textfield;
}

.outline-0 {
    outline: none !important;
}

.audio-bar{
    height: 40px;
    width: 250px;
}